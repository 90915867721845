import React from 'react'
import logo from './orri-logo-2022-papaya.png'
import useFully from './hooks/use-fully'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCloudExclamation,
  faWifiSlash,
  faCircleXmark,
  faCheckCircle,
} from '@fortawesome/pro-duotone-svg-icons'

const App = () => {
  const fully = useFully()
  const ethernetMacAddress =
    fully?.getMacAddressForInterface('eth0') || 'Not available'
  const serialNumber = fully?.getSerialNumber() || 'Not available'
  const ipAddress = fully?.getIp4Address() || 'Not available'

  return (
    <div className="p-6 flex flex-col items-center justify-center  bg-brand-marshmallow h-screen w-screen">
      <img className="w-72 mb-6" src={logo} alt="Orri logo papaya coloured" />
      <div className="flex space-x-6 items-center">
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-3xl text-brand-leaf"
        />
        <div className="flex flex-col space-y-2">
          <div className="font-jakarta text-lg text-brand-charcoal">
            Connected to test system
          </div>
          <div className="font-mono text-sm flex flex-col spacex-y-1">
            <span>Mac Address:&nbsp;{ethernetMacAddress}</span>
            <span>Serial No:&nbsp;&nbsp;&nbsp;{serialNumber}</span>
            <span>IP Address:&nbsp;&nbsp;{ipAddress}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
